.avatar {
  width: 30px;
  height: 30px;
  &.large {
    @media (min-width: 768px) {
      width: 45px;
      height: 45px;
    }
  }
  &.extraLarge {
    @media (min-width: 768px) {
      width: 70px;
      height: 70px;
    }
  }
}