.popover {
  > div {
    padding: 1px 10px 3px 10px !important;
  }
  &.green500 {
    @apply bg-green-500 #{!important};
    span:first-of-type {
      @apply border-r-green-500 #{!important};
    }
  }
  &.blue500 {
    @apply bg-blue-500 #{!important};
    span:first-of-type {
      @apply border-r-blue-500 #{!important};
    }
  }
  &.orange400 {
    @apply bg-orange-400 #{!important};
    span:first-of-type {
      @apply border-r-orange-400 #{!important};
    }
  }
  &.red700 {
    @apply bg-red-700 #{!important};
    span:first-of-type {
      @apply border-r-red-700 #{!important};
    }
  }
  &.red500 {
    @apply bg-red-500 #{!important};
    span:first-of-type {
      @apply border-r-red-500 #{!important};
    }
  }
  &.pink600 {
    @apply bg-pink-600 #{!important};
    span:first-of-type {
      @apply border-r-pink-600 #{!important};
    }
  }
}